<template>
  <el-row class="tac">
    <el-col           v-if="show"
                      :span="menu_span">
      <h3 v-if="!isCollapse" class="person_body_list">个人中心</h3>
      <el-menu
          :unique-opened="true"
          :collapse="isCollapse"
          :router="true"
          default-active="/user/center/"
      >
        <el-sub-menu index="1">
          <template #title>
            <el-icon><Postcard /></el-icon>
            <span>账号信息</span>
          </template>
            <el-menu-item  index="/user/center/">个人简介</el-menu-item>
            <el-menu-item index="1-2">账号管理</el-menu-item>

        </el-sub-menu>
        <el-sub-menu index="2">
          <template #title>
            <el-icon><Notebook /></el-icon>
            <span>文章管理</span>
          </template>
          <el-menu-item index="/user/center/articlelist/">全部文章</el-menu-item>
          <el-menu-item index="2-2">草稿箱</el-menu-item>

        </el-sub-menu>
        <el-menu-item index="/article/write">
          <el-icon :size="22"  ><EditPen color="#FF0000" /></el-icon>
          <template  #title>发布文章</template>
        </el-menu-item>
        <el-sub-menu index="4">
          <template #title>
            <el-icon><DataAnalysis/></el-icon>
            <span>
              缠论</span>
          </template>
          <el-menu-item index="/user/center/chanlun/">选股</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="5">
          <template #title>
            <el-icon><List /></el-icon>
            <span>
              日程计划</span>
          </template>
          <el-menu-item index="/user/center/schedule/">日程</el-menu-item>
          <el-menu-item index="/user/center/todolist/">计划</el-menu-item>

        </el-sub-menu>

        <el-sub-menu index="6">
          <template #title>
            <el-icon><Setting/></el-icon>
            <span>
              站点设置</span>
          </template>
          <el-menu-item index="/user/center/basesetting/">网站基础设置</el-menu-item>
          <el-menu-item index="/user/center/carousel/">首页轮播图</el-menu-item>

        </el-sub-menu>
      </el-menu>
    </el-col>
    <el-col :span="content_span" >
      <el-card >
      <el-icon @click="handl_menu" v-if="isCollapse" :size="24"><Expand /></el-icon>
      <el-icon @click="handl_menu" v-else :size="24"><Fold /></el-icon>
        <router-view style="text-align: center" ></router-view>
      </el-card>
    </el-col>
  </el-row>
</template>

<script setup>
import {ref,onMounted } from "vue";
const isCollapse= ref(false)
const menu_span= ref(3)
const content_span= ref(21)
const show=ref(true)
const handl_menu=()=>{
  if (window.innerWidth<768){
    show.value=true
    menu_span.value=3
    content_span.value=21
    return
  }
  isCollapse.value=!isCollapse.value

  if(isCollapse.value){

    menu_span.value=2
    content_span.value=22
    show.value=true

  }else{
    if (window.innerWidth<768){
      show.value=false
      isCollapse.value=true
    }
      menu_span.value=3
    isCollapse.value=false
    content_span.value=21
  }
}
 onMounted(()=>{
   if (window.innerWidth<768){
     isCollapse.value=true
     content_span.value=24
     show.value=false
   }else {
     isCollapse.value=false
     menu_span.value = 3
   }

  window.addEventListener('resize',()=>{
    if (window.innerWidth<768){
      show.value=false
      content_span.value=24

    }else {
      isCollapse.value=false
      show.value=true
      content_span.value=21

    }
  })
})


</script>

<style scoped>
.person_body_list {
  width: 100%;
  height: 50px;
  margin: 0 20%;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
  background-image: -webkit-linear-gradient(
      rgb(42, 134, 141),
      #e9e625dc 20%,
      #3498db 40%,
      #e74c3c 60%,
      #09ff009a 80%,
      rgba(82, 196, 204, 0.281) 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-background-size: 200% 100%;
  -webkit-animation: masked-animation 4s linear infinite;
}

</style>